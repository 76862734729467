import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.854"
      height="31.854"
      x="0"
      y="0"
      enableBackground="new 0 0 31.854 31.854"
      version="1.1"
      viewBox="0 0 31.854 31.854"
      xmlSpace="preserve"
    >
      <path d="M15.94 11.735c0 .588-.476 1.064-1.065 1.064H5.298a1.064 1.064 0 110-2.127h9.577c.59 0 1.065.476 1.065 1.063zm5.58-.648l5.342 4.791-11.327 12.627-5.34-4.791L21.52 11.087zm-1.512 5.101l-.033-.029a.64.64 0 00-.9.048l-6.079 6.776a.682.682 0 00.052.965.627.627 0 00.461.164.64.64 0 00.44-.213l6.108-6.81a.638.638 0 00-.049-.901zm1.262-2.437l-.301.336a.653.653 0 00-.162.461c.01.169.086.328.211.441l.035.031a.643.643 0 00.461.162.632.632 0 00.44-.211l.728-.811-.51-.457a.639.639 0 00-.902.048zm.765-3.241l5.341 4.791 1.312-1.462-5.34-4.791-1.313 1.462zm9.012.7l-1.877 2.093-5.341-4.79 1.88-2.093a1.412 1.412 0 011.996-.109l3.234 2.903c.581.521.63 1.415.108 1.996zm-1.051-.946l-3.233-2.902-.937 1.042 3.233 2.903.937-1.043zM9.557 24.428l5.339 4.791-6.31 2.586a.685.685 0 01-.917-.824l1.888-6.553zm.488 1.702l-.722 2.507 1.411 1.268 2.412-.986-3.101-2.789zm-1.83-1.845l-.623 2.162H2.859a2.413 2.413 0 01-2.413-2.412V6.493A2.413 2.413 0 012.859 4.08h2.94v-.174a.89.89 0 01.89-.89h.74A2.664 2.664 0 0110.067 0a2.663 2.663 0 012.638 3.015h.749a.89.89 0 01.89.89v.174h2.87a2.413 2.413 0 012.412 2.413v4.63l-2.128 2.372V7.506c0-.699-.565-1.265-1.264-1.265h-1.891v1.201a.89.89 0 01-.89.89H6.69a.89.89 0 01-.89-.89V6.241H3.874c-.699 0-1.265.567-1.265 1.265V23.02c0 .701.567 1.266 1.265 1.266h4.341zm.788-21.624c0 .124.023.248.061.355h2.005a1.066 1.066 0 00-1.002-1.42c-.588 0-1.064.478-1.064 1.065zm5.946 13.68l.991-1.105a1.077 1.077 0 00-1.064-1.054H5.298a1.08 1.08 0 00-1.065 1.082 1.08 1.08 0 001.065 1.082h9.577l.074-.005zm-10.716 2.45c0 .588.477 1.062 1.065 1.062H11.8l1.907-2.127H5.298a1.066 1.066 0 00-1.065 1.065z"></path>
    </svg>
  );
}

export default Icon;
